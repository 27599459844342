import { useEffect, useState } from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";

import { ResponsiveTable, TransactionsTable, TransactionsItem } from "./styled";

import { useTransactions } from "../../../context/TransactionsContext";
import { useModal } from "../../../context/ModalContext";

// Services API
import { getTransactions, updateTransaction, deleteTransactionApi } from "../../../services/Transactions";

// Hooks
import { formatCurrency } from "../../../hooks/FormatCurrency";
import { formatDate } from "../../../hooks/FormatDate";
import { getCategoryProperties } from "../../../hooks/GetCategoryProperties";
import { getClassType } from "../../../hooks/GetClassType";

import { TransactionsModal } from "../TransactionsModal";

export const Transactions = () => {
    const { transactions, setTransactions } = useTransactions();
    const [transactionsLength, setTransactionsLength] = useState<Boolean>(false);
    const [message, setMessage] = useState("Carregando...");
    const { isOpen, toggleModal } = useModal();
    const [transactionForEdit, setTransactionForEdit] = useState(null);

    const fetchTransactions = async (month = new Date().getMonth() + 1, year = new Date().getFullYear()) => {
        let transactionsGetted = await getTransactions(month, year);
        setTransactions(transactionsGetted);

        if (transactionsGetted.length === 0) {
            setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
            setTransactionsLength(false);
        } else {
            setTransactionsLength(true);
        }
    };

    useEffect(() => {
        if (transactions.length === 0) {
            setTimeout(() => {
                setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
            }, 2000);
            setTransactionsLength(false);
        } else {
            setTransactionsLength(true);
        }
    }, [transactions]);

    useEffect(() => {
        fetchTransactions();
    }, [setTransactions]);

    const changeTransactionStatus = async (id: number) => {
        let transaction = transactions.find((transaction) => transaction.id === id);

        if (transaction) {
            let newTransactionsStatus = transaction.status === "not_paid" ? "paid" : "not_paid";

            transaction.status = newTransactionsStatus;

            setTransactions([...transactions]);

            await updateTransaction(id, {
                status: newTransactionsStatus,
            });
        }
    };

    const deleteTransaction = async (id: number) => {
        let transaction = transactions.find((transaction) => transaction.id === id);

        if (transaction) {
            await deleteTransactionApi(id);

            let index = transactions.indexOf(transaction);
            if (index > -1) {
                transactions.splice(index, 1);
            }
            setTransactions([...transactions]);
        }

        if (transactions.length === 0) {
            setMessage("Nenhuma transação encontrada. Cadastre a primeira!");
            setTransactionsLength(false);
        } else {
            setTransactionsLength(true);
        }
    };

    const editTransaction = (transaction) => {
        setTransactionForEdit(transaction);
        toggleModal();
    };

    useEffect(() => {
        !isOpen && setTransactionForEdit(null);
    }, [toggleModal]);

    return (
        <div>
            {transactionForEdit && <TransactionsModal type={"edit"} transaction={transactionForEdit} />}

            <ResponsiveTable>
                <TransactionsTable>
                    <thead className={transactionsLength === true ? "visible" : "invisible"}>
                        <tr>
                            <th>Dia</th>
                            <th>Título</th>
                            <th>Categoria</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionsLength ? (
                            transactions.map((transaction) => (
                                <TransactionsItem key={transaction.id}>
                                    <td>{formatDate(transaction.date).substring(0, 5)}</td>
                                    <td>
                                        {transaction.title}{" "}
                                        {transaction.isRecurrent ? `${transaction.installmentNumber} / ${transaction.installmentTimes}` : ""}
                                    </td>
                                    <td>
                                        {transaction.category === null || transaction.category === 0
                                            ? "Outros"
                                            : getCategoryProperties(transaction.category)}
                                    </td>
                                    <td>
                                        <span className={"value " + getClassType(transaction.type)}>{formatCurrency(transaction.amount)}</span>
                                    </td>
                                    <td className="changeStatus" onClick={() => changeTransactionStatus(transaction.id)}>
                                        {transaction.status === "not_paid" ? <AiFillDislike /> : <AiFillLike className="paid" />}
                                    </td>
                                    <td>
                                        <div>
                                            <MdEdit
                                                title="Editar"
                                                onClick={() => {
                                                    editTransaction(transaction);
                                                }}
                                                className="edit"
                                            />
                                            <FaTrash
                                                title="Excluir"
                                                onClick={() => {
                                                    deleteTransaction(transaction.id);
                                                }}
                                            />
                                        </div>
                                    </td>
                                </TransactionsItem>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5}>{message}</td>
                            </tr>
                        )}
                    </tbody>
                </TransactionsTable>
            </ResponsiveTable>
        </div>
    );
};
