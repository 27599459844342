import "./App.css";

import { RouterProvider } from "react-router-dom";
import { TransactionsProvider } from "./context/TransactionsContext";
import { ModalProvider } from "./context/ModalContext";

import { router } from "./Routes";

function App() {
    return (
        <ModalProvider>
            <TransactionsProvider>
                <RouterProvider router={router}></RouterProvider>
            </TransactionsProvider>
        </ModalProvider>
    );
}

export default App;
