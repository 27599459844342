// Alimentação
// Assinatura e Serviços
// Bares e Restaurantes
// Casa
// Compras
// Cuidados pessoais
// Dívidas e Empréstimos
// Educação
// Família e Filhos
// Impostos e Taxas
// Investimentos
// Lazer e Hobbies
// Mercado
// Outros
// Pets
// Presentes e Doações
// Roupas
// Saúde
// Trabalho
// Transporte
// Viagem

export const categories = [
    {
        id: 1,
        title: "Alimentação",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 2,
        title: "Assinatura e Serviços",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 3,
        title: "Bares e Restaurantes",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 4,
        title: "Casa",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 5,
        title: "Compras",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 6,
        title: "Cuidados pessoais",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 7,
        title: "Dívidas e Empréstimos",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 8,
        title: "Educação",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 9,
        title: "Família e Filhos",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 10,
        title: "Impostos e Taxas",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 11,
        title: "Investimentos",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 12,
        title: "Lazer e Hobbies",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 13,
        title: "Mercado",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 14,
        title: "Outros",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 15,
        title: "Pets",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 16,
        title: "Presentes e Doações",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 17,
        title: "Roupas",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 18,
        title: "Saúde",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 19,
        title: "Trabalho",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 20,
        title: "Transporte",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    {
        id: 21,
        title: "Viagem",
        type: "expense",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
];
