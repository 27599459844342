import { createBrowserRouter, Link } from "react-router-dom";

import { Header } from "./layout/Header";

import { PrivateRoute } from "./components/PrivateRoutes";
import { DashboardPage } from "./pages/Dashboard";
import { LoginPage } from "./pages/Login";
import { RegisterPage } from "./pages/Register";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <PrivateRoute>
                <Header />
                <div className="container">
                    <DashboardPage />
                </div>
            </PrivateRoute>
        ),
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/register",
        element: <RegisterPage />,
    },
]);
