const Theme = {
    colors: {
        primary: "#3D63DD",
        secondary: "#2247C0",
        tertiary: "#0066f5",
        hover: "#291ad9",
        text: "#4A4F4F",
        link: "#141414",
        // green: "#3F6213",
        green: "#33CC95",
        greenValue: "#00b37e",
        greenVariation: "#015f43",
        red: "#E52E4D",
        gray: "#d1d3d4",
        grayBlack: "#a0a0a0",
        white: "#fbfbfb",
        // background: "#141827",
        // primary: {
        //     main: "#fffae3",
        //     contrastText: "#c1d9d0",
        // },
        // secundary: {
        //     main: "#736681",
        //     contrastText: "#62455b",
        // },
    },

    border: {
        radiusSmall: "5px",
        radius: "10px",
        style: "1px solid #4a4f4f",
        color: "#4a4f4f",
    },

    font: {
        small: "0.5rem",
        default: "1rem",
        big: "2rem",
        bigger: "3rem",
    },
};

export default Theme;
