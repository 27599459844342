import { useState, useEffect } from "react";
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown } from "react-icons/fa";
import { LuRepeat2 } from "react-icons/lu";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { RadioBox, TransactionsModalContainer, TransactionTypeContainer } from "./styled";

import { NumericFormat } from "react-number-format";

import { formatCurrency } from "../../../hooks/FormatCurrency";

// Services API
import { saveTransaction, updateTransaction } from "../../../services/Transactions";
import { categories } from "../../../services/Categories";

// Context
import { useModal } from "../../../context/ModalContext";
import { useTransactions } from "../../../context/TransactionsContext";

export const TransactionsModal = (props) => {
    const { isOpen, toggleModal } = useModal();
    const { transactions, setTransactions } = useTransactions();

    const [title, setTitle] = useState("");
    const [amount, setAmount] = useState("");
    const [category, setCategory] = useState("");
    const [type, setType] = useState<"income" | "expense">("expense");
    const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
    const [status, setStatus] = useState<"paid" | "not_paid">("not_paid");
    const [description, setDescription] = useState("");
    const [typeModal, setTypeModal] = useState("new");

    // Recorrência
    const [isRecurrent, setIsRecurrent] = useState(false);
    const [recurrenceAmount, setRecurrenceAmount] = useState(0);
    const [recurrenceStart, setRecurrenceStart] = useState(new Date());
    const [recurrenceEnd, setRecurrenceEnd] = useState(new Date());
    const [installmentValue, setInstallmentValue] = useState("");
    const [times, setTimes] = useState(1);
    const [done, setDone] = useState(0);
    const [recurrenceType, setRecurrenceType] = useState<"finite" | "infinite">("finite");
    const [periodicity, setPeriodicity] = useState<"monthly" | "bimonthly" | "quarterly" | "semiannual" | "annual">("monthly");
    const [recurrenceStatus, setRecurrenceStatus] = useState<"completed" | "canceled" | "active">("active");
    const [inInstallments, setInInstallments] = useState(false);

    useEffect(() => {
        if (props.type === "edit") {
            setTitle(props.transaction.title);
            setAmount(props.transaction.amount);
            setCategory(props.transaction.category);
            setType(props.transaction.type);
            setDate(new Date(props.transaction.date).toISOString().split("T")[0]);
            setStatus(props.transaction.status);
            setDescription(props.transaction.description);
            setTypeModal("edit");
        } else {
            setTitle("");
            setAmount("");
            setCategory("");
            setType("expense");
            // setDate(new Date().toISOString().toString());
            setStatus("not_paid");
            setDescription("");
        }
    }, []);

    const handleSubmit = async (Event: React.FormEvent) => {
        Event.preventDefault();

        // await handleRecurrenceType(recurrenceType);

        let dateForBd = new Date(date + "T05:00:00.000Z").toISOString();

        let newTransactionData = {
            id: 0,
            title,
            description,
            amount: Number(amount.replace(",", ".")),
            type,
            category: Number(category),
            date: dateForBd.toString(),
            status,
            isRecurrent,
        };

        if (typeModal === "new") {
            newTransactionData["recurrenceData"] = {
                recurrenceAmount: recurrenceAmount,
                recurrenceStart: recurrenceStart,
                recurrenceEnd: recurrenceEnd,
                installmentValue: Number(installmentValue),
                times: times,
                done: done,
                type: recurrenceType,
                periodicity: periodicity,
                status: recurrenceStatus,
            };
        }

        let transactionAddedd =
            typeModal === "new" ? await saveTransaction(newTransactionData) : await updateTransaction(props.transaction.id, newTransactionData);

        if (typeModal === "new") {
            setTransactions([...transactions, ...transactionAddedd]);
        } else {
            let transaction = transactions.find((transaction) => transaction.id === props.transaction.id);
            let index = transactions.indexOf(transaction);
            transactions[index] = transactionAddedd[0];
            setTransactions([...transactions]);
        }

        handleModal();
    };

    const handleModal = () => {
        setTitle("");
        setAmount("");
        setCategory("");
        setType("expense");
        setDate(new Date().toISOString().substring(0, 10));
        setStatus("not_paid");
        setDescription("");
        toggleModal();
        props = {};
    };

    const handleRecurrenceType = async (type) => {
        if (type == "infinite") {
            setInInstallments(false);

            setRecurrenceAmount(Number(amount));
            setRecurrenceStart(new Date(date + "T05:00:00.000Z"));
            setRecurrenceEnd(new Date(date + "T05:00:00.000Z"));
            setInstallmentValue(amount);
            setTimes(36);
            setDone(0);
            setRecurrenceType("infinite");
            setPeriodicity("monthly");
            setRecurrenceStatus("active");
        } else {
            setInInstallments(true);

            setRecurrenceAmount(Number(amount));
            setRecurrenceStart(new Date(date + "T05:00:00.000Z"));
            setRecurrenceEnd(new Date(date + "T05:00:00.000Z"));
            setInstallmentValue(String(Number(amount) / times));
            setTimes(1);
            setDone(0);
            setRecurrenceType("finite");
            setPeriodicity("monthly");
            setRecurrenceStatus("active");
        }
    };

    const handleRecurrenceInstallmentValue = (times) => {
        setTimes(Number(times));
        setInstallmentValue(String(Number(amount) / times));
    };

    return (
        <TransactionsModalContainer className={isOpen ? "opened" : ""}>
            <div className="transactionsModal__container">
                <div className="transactionsModal__body">
                    <span
                        onClick={() => {
                            handleModal();
                        }}
                    >
                        x
                    </span>
                    <h1>Nova Transação</h1>
                    <form
                        onSubmit={(Event) => {
                            handleSubmit(Event);
                        }}
                    >
                        <TransactionTypeContainer>
                            <RadioBox
                                type="button"
                                onClick={() => {
                                    setType("income");
                                }}
                                isActive={type === "income"}
                                activeColor="green"
                            >
                                <FaRegArrowAltCircleUp className="income" />
                                <span>Entrada</span>
                            </RadioBox>
                            <RadioBox
                                type="button"
                                onClick={() => {
                                    setType("expense");
                                }}
                                isActive={type === "expense"}
                                activeColor="red"
                            >
                                <FaRegArrowAltCircleDown className="expense" />
                                <span>Saída</span>
                            </RadioBox>
                        </TransactionTypeContainer>
                        <input
                            type="text"
                            placeholder="Título"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                        <NumericFormat
                            value={amount}
                            onValueChange={(values) => {
                                const { value } = values;
                                setAmount(value);
                            }}
                            required
                            placeholder={"Valor"}
                            allowLeadingZeros={false}
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            decimalSeparator={","}
                            allowedDecimalSeparators={["."]}
                            thousandSeparator="."
                            prefix={"R$ "}
                        />
                        <select
                            value={category}
                            onChange={(e) => {
                                setCategory(e.target.value);
                            }}
                        >
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.title}
                                </option>
                            ))}
                        </select>
                        <select
                            value={status}
                            onChange={(e) => {
                                setStatus(e.target.value as "paid" | "not_paid");
                            }}
                        >
                            <option value="not_paid">Não pago</option>
                            <option value="paid">Pago</option>
                        </select>
                        <input
                            type="date"
                            placeholder="Data"
                            value={date}
                            onChange={(e) => {
                                setDate(e.target.value);
                            }}
                        />
                        {/* <textarea
                            placeholder="Descrição"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        ></textarea> */}
                        <div className={`isRecurrenceBtn ${isRecurrent ? "active" : ""}`} onClick={() => setIsRecurrent(!isRecurrent)}>
                            <LuRepeat2 />
                            <span>Repetir</span>
                        </div>
                        <div className={`isRecurrenceOptions ${isRecurrent ? "active" : ""}`}>
                            <label htmlFor="transactionFixed">
                                <input
                                    type="radio"
                                    name="Fixo"
                                    id="transactionFixed"
                                    onChange={() => {
                                        handleRecurrenceType("infinite");
                                    }}
                                />
                                <span>Despesa fixa</span>
                            </label>
                            <label htmlFor="transactionFixedTwo">
                                <input
                                    type="radio"
                                    name="Fixo"
                                    id="transactionFixedTwo"
                                    onChange={() => {
                                        handleRecurrenceType("finite");
                                    }}
                                />
                                <span>Despesa parcelada</span>
                            </label>
                        </div>
                        <div className={`isRecurrenceInstallments ${isRecurrent && inInstallments ? "active" : ""}`}>
                            <label htmlFor="installmentsValues">Número de parcelas</label>
                            <input
                                id="installmentsValues"
                                type="number"
                                placeholder="Número de parcelas"
                                min={1}
                                value={times}
                                onChange={(e) => {
                                    handleRecurrenceInstallmentValue(e.target.value);
                                }}
                            />
                            <span>
                                <IoIosInformationCircleOutline />
                                Será gerado {times} parcelas de {formatCurrency(Number(installmentValue))}
                            </span>
                        </div>
                        <button className="send">{props.type === "new" ? "Adicionar" : "Salvar"}</button>
                    </form>
                </div>
            </div>
        </TransactionsModalContainer>
    );
};
