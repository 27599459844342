import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";

import { HeaderContainer, HeaderUserMenu } from "./styled";

import { TransactionsModal } from "../../components/Dashboard/TransactionsModal";
import { useModal } from "../../context/ModalContext";
import useAuthStore from "../../stores/AuthStore";

import { getUser } from "../../services/User";

import Logo from "../../assets/logo.png";

export const Header = () => {
    const [username, setUsername] = useState("");

    const { toggleModal } = useModal();
    const { logout } = useAuthStore();
    const navigate = useNavigate();

    function toggleLogOut() {
        logout();
        navigate("/login");
    }

    useEffect(() => {
        getUser().then((response) => {
            setUsername(response[0].name);
        });
    }, []);

    return (
        <HeaderContainer>
            <div className="container">
                <img src={Logo} alt="Logo Finances App" />

                <div>
                    <IoIosAddCircle
                        onClick={() => {
                            toggleModal();
                        }}
                    />
                    <HeaderUserMenu>
                        {username && (
                            <>
                                Bem-vindo(a), <strong>{username}</strong>
                            </>
                        )}

                        <div>
                            <span>Categorias</span>
                            <span>Meu plano</span>
                            <span>Configurações</span>
                            <span onClick={() => toggleLogOut()}> Logout</span>
                        </div>
                    </HeaderUserMenu>
                </div>
            </div>
            <TransactionsModal type={"new"} />
        </HeaderContainer>
    );
};
