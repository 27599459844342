import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BodyLogin, LoginContainer } from "./styled";

import Logo from "../../assets/logo.png";

import { login } from "../../services/Auth";
import useAuthStore from "../../stores/AuthStore";

export const LoginPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    // const handleShowClick = () => setShowPassword(!showPassword);

    const { loginStore, isLoggedIn } = useAuthStore();
    const navigate = useNavigate();

    async function action(event: React.FormEvent) {
        event.preventDefault();
        await login({ email: email, password: password })
            .then((response) => {
                const userDetails = response.data;
                loginStore(userDetails);
                navigate("/");
            })
            .catch((error) => {
                alert(error.response.data.message);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (isLoggedIn()) {
            navigate("/");
        }

        if (searchParams.get("accountCreated")) {
            setAlertMessage("Conta criada com sucesso! Faça login para acessar.");
            document.querySelector(".alert")?.classList.add("alert--success");
            setSearchParams({});
        }
    }, []);

    return isLoggedIn() ? (
        <></>
    ) : (
        <BodyLogin>
            <img src={Logo} alt="Logo Finances App" />

            <LoginContainer>
                <h1>Área Exclusiva</h1>
                <p>Insira os seus dados para acessar sua conta.</p>
                <div className="alert">{alertMessage}</div>
                <form onSubmit={action}>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
                    <input type={showPassword ? "text" : "password"} placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />
                    <button
                        type="submit"
                        onClick={() => {
                            setLoading(!loading);
                        }}
                    >
                        {loading ? "Fazendo login..." : "Login"}
                    </button>
                </form>
                <Link to="/register">Criar uma conta</Link>
            </LoginContainer>
        </BodyLogin>
    );
};
