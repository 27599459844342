import styled from "styled-components";

export const BodyLogin = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;

    img {
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
        max-width: 250px;
        margin-bottom: 3rem;
    }
`;

export const LoginContainer = styled.div`
    width: 100%;
    max-width: 350px;
    text-align: center;

    h1 {
        color: ${(props) => props.theme.colors.primary};
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }

    a {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.text};
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .alert {
        display: none;
        width: 100%;
        color: white;
        font-size: 1rem;
        font-family: "Popins", sans-serif;
        line-height: 1.5;
        padding: 0.5rem 4rem;
        opacity: 1;

        &--success {
            display: block;
            background: ${(props) => props.theme.colors.green};
        }
    }

    form {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 1.5rem;

        input {
            display: block;
            padding: 0.7rem 0.5rem;
            width: 100%;
            background: ${(props) => props.theme.colors.white};
            border-radius: ${(props) => props.theme.border.radius};
            border: ${(props) => props.theme.border.style};

            & + input {
                margin-top: 1rem;
            }
        }

        button {
            margin-top: 1.5rem;
            background: ${(props) => props.theme.colors.primary};
            border-color: ${(props) => props.theme.colors.primary};
            text-decoration: none;
            transition: all 0.2s ease-in-out;
            padding: 7px 30px;
            border-radius: 5px;
            white-space: normal;
            cursor: pointer;

            font-size: 1rem;
            line-height: 1.5;
            font-weight: 400;
            color: white;

            &:hover {
                background: ${(props) => props.theme.colors.hover};
                border-color: ${(props) => props.theme.colors.hover};
            }
        }
    }
`;
