import React, { createContext, useContext, ReactNode, useState } from "react";

interface ModalContextProps {
    isOpen: boolean;
    toggleModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen((prev) => !prev);
    };

    return <ModalContext.Provider value={{ isOpen, toggleModal }}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("userModal must be userd within a ModalProvider");
    }
    return context;
};
