import { Summary } from "../../components/Dashboard/Summary";
import { TransactionsNavigator } from "../../components/Dashboard/TransactionsNavigator";
import { Transactions } from "../../components/Dashboard/Transactions";

export const DashboardPage = () => {
    return (
        <>
            <Summary />
            <TransactionsNavigator />
            <Transactions />
        </>
    );
};
