import styled from "styled-components";

export const MonthNavigator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 3rem;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 20px;
            cursor: pointer;
            fill: ${(props) => props.theme.colors.secondary};
        }
    }

    /* span {
        position: relative;
        width: 25px;
        height: 25px;
        font-size: 20px;
        cursor: pointer;
        transition: 0.5s all;
        border-radius: 5px;

        &:first-child {
            &:before {
                content: "<";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:last-child {
            &:after {
                content: ">";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            background: gray;
        }
    } */

    .item {
        display: none;
        min-width: 180px;
        text-align: center;
        font-size: 20px;
        font-family: "poppins", sans-serif;
        color: ${(props) => props.theme.colors.text};
        font-weight: 500;

        &.active {
            display: block;
        }
    }
`;
