import { SummaryContainer } from "./styled";

import { useTransactions } from "../../../context/TransactionsContext";

import { getTotalIncome, getTotalExpenses, getTotal } from "../../../hooks/GetTotals";
import { formatCurrency } from "../../../hooks/FormatCurrency";

export const Summary = () => {
    const { transactions } = useTransactions();
    let totalIncome = getTotalIncome(transactions);
    let totalExpense = getTotalExpenses(transactions);
    let total = getTotal(transactions);

    return (
        <SummaryContainer>
            <div>
                <span className="title">Entradas</span>
                <span className="value">{formatCurrency(totalIncome.total)}</span>

                <small>Previsto: {formatCurrency(totalIncome.forecast)}</small>
            </div>
            <div>
                <span className="title">Saídas</span>
                <span className="value">{formatCurrency(totalExpense.total)}</span>
                <small>Previsto: {formatCurrency(totalExpense.forecast)}</small>
            </div>
            <div className="total">
                <span className="title">Total</span>
                <span className="value">{formatCurrency(total.total)}</span>
                <small>Previsto: {formatCurrency(total.forecast)}</small>
            </div>
        </SummaryContainer>
    );
};
