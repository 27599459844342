import React, { createContext, useState, useContext, ReactNode } from "react";

interface Transaction {
    category: number;
    status: string;
    id: number;
    title: string;
    amount: number;
    type: string;
    date: string;
    isRecurrent: boolean;
    installmentNumber: number;
    installmentTimes: number;
}

interface TransactionsContextData {
    transactions: Transaction[];
    setTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
}

const TransactionsContext = createContext<TransactionsContextData | undefined>(undefined);

export const TransactionsProvider = ({ children }: { children: ReactNode }) => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);

    return <TransactionsContext.Provider value={{ transactions, setTransactions }}>{children}</TransactionsContext.Provider>;
};

export const useTransactions = () => {
    const context = useContext(TransactionsContext);
    if (!context) {
        throw new Error("useTransactions must be used within a TransactionsProvider");
    }
    return context;
};
