import { api } from "./InstanceAxios";

interface IUser {
    name: string;
}

export async function getUser(): Promise<IUser[]> {
    const token = localStorage.getItem("accessToken");

    let userInfo = [];

    await api
        .get(`users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            userInfo = [...response.data.data];
        })
        .then((error) => {
            return error;
        });

    return userInfo;
}
