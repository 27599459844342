import { orderByDate } from "../hooks/OrderByDate";

import { api } from "./InstanceAxios";

interface ITransaction {
    id: number;
    title: string;
    description: string;
    amount: number;
    type: "income" | "expense";
    category: number;
    date: string;
    status: "paid" | "not_paid";
    isRecurrent: boolean;
    installmentNumber: number;
    installmentTimes: number;
}

interface ITransactionSave {
    id: number;
    title: string;
    description: string;
    amount: number;
    type: "income" | "expense";
    category: number;
    date: string;
    status: "paid" | "not_paid";
}

export async function getTransactions(month = new Date().getMonth() + 1, year = new Date().getFullYear()): Promise<ITransaction[]> {
    let transactions = [];
    const token = localStorage.getItem("accessToken");

    await api
        .get(`transactions?month=${month}&year=${year}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            transactions = await orderByDate(response.data);
        })
        .then((error) => {
            return error;
        });

    return transactions;
}

export async function saveTransaction(transaction: ITransactionSave) {
    let transactionAdded = [];
    const token = localStorage.getItem("accessToken");

    delete transaction["id"];

    await api
        .post("transactions", transaction, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(async (response) => {
            transactionAdded.push(response.data);
        })
        .then((error) => {
            return error;
        });

    return transactionAdded;
}

export async function updateTransaction(id: number, data: {}): Promise<any> {
    let transactionUpdated = [];
    const token = localStorage.getItem("accessToken");

    delete data["id"];

    await api
        .patch(
            "transactions",
            {
                id: id,
                data: data,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then(async (response) => {
            transactionUpdated.push(response.data.data);
        })
        .then((error) => {
            return error;
        });

    return transactionUpdated;
}

export async function deleteTransactionApi(id: number): Promise<any> {
    let transactions = [];
    const token = localStorage.getItem("accessToken");

    api.delete("transactions", {
        data: {
            id: id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(async (response) => {
            transactions = response.data;
        })
        .then((error) => {
            return error;
        });

    return transactions;
}
