export const getTotalIncome = (transactions: any[]) => {
    let totalIncome = {
        total: 0,
        forecast: 0,
    };

    transactions.forEach((transaction) => {
        if (transaction.type === "income") {
            if (transaction.status === "paid") {
                totalIncome.total += transaction.amount;
            }

            totalIncome.forecast += transaction.amount;
        }
    });

    totalIncome.total = totalIncome.total < 0 ? 0 : totalIncome.total;

    return totalIncome;
};

export const getTotalExpenses = (transactions: any[]) => {
    let totalExpenses = {
        total: 0,
        forecast: 0,
    };

    transactions.forEach((transaction) => {
        if (transaction.type === "expense") {
            if (transaction.status === "paid") {
                totalExpenses.total += transaction.amount;
            }

            totalExpenses.forecast += transaction.amount;
        }
    });

    totalExpenses.total = totalExpenses.total < 0 ? 0 : totalExpenses.total;

    return totalExpenses;

    // return transactions.reduce((acc: any, transaction: { type: string; amount: any }) => {
    //     return transaction.type === "expense" ? acc + transaction.amount : acc;
    // }, 0);
};

export const getTotal = (transactions: any[]) => {
    let total = {
        total: 0,
        forecast: 0,
    };

    transactions.forEach((transaction) => {
        if (transaction.status === "paid") {
            transaction.type === "income" ? (total.total = total.total + transaction.amount) : (total.total = total.total - transaction.amount);
        }

        transaction.type === "income"
            ? (total.forecast = total.forecast + transaction.amount)
            : (total.forecast = total.forecast - transaction.amount);
    });

    total.total = total.total < 0 ? 0 : total.total;

    return total;

    // return transactions.reduce((acc: number, transaction: { type: string; amount: number }) => {
    //     return transaction.type === "income" ? acc + transaction.amount : acc - transaction.amount;
    // }, 0);
};
