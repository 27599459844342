import styled from "styled-components";

export const SummaryContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    margin: 4rem 0;

    @media (min-width: 998px) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    > div {
        width: 100%;
        background: ${(props) => props.theme.colors.white};
        border: ${(props) => props.theme.border.style};
        border-radius: ${(props) => props.theme.border.radius};
        padding: 1rem;

        @media (min-width: 998px) {
            padding: 1.5rem 2rem;
        }

        &.total {
            background: ${(props) => props.theme.colors.green};
            border-color: ${(props) => props.theme.colors.green};

            span,
            small {
                color: ${(props) => props.theme.colors.white} !important;
            }
        }

        span {
            display: block;

            &.title {
                color: ${(props) => props.theme.colors.text};
                font-size: 1rem;
                margin-bottom: 10px;
            }

            &.value {
                color: ${(props) => props.theme.colors.primary};
                font-size: 2rem;
                font-weight: 700;
                font-family: "Poppins", sans-serif;
            }
        }
    }
`;
