import { useEffect } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import { MonthNavigator } from "./styled";

import { useTransactions } from "../../../context/TransactionsContext";

import { getTransactions } from "../../../services/Transactions";

export const TransactionsNavigator = () => {
    const { setTransactions } = useTransactions();

    const fetchTransactions = async (month = new Date().getMonth() + 1, year = new Date().getFullYear()) => {
        let transactionsGetted = await getTransactions(month, year);
        setTransactions(transactionsGetted);
    };

    useEffect(() => {
        generateMonthNavigator();
    }, []);

    const generateMonthNavigator = (month = new Date().getMonth() + 1, year = new Date().getFullYear()) => {
        document.querySelector(".js-monthNavigator").innerHTML = "";

        let months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

        let div = document.createElement("div");
        div.classList.add("item", "active");
        div.setAttribute("data-month", String(month));
        div.setAttribute("data-year", String(year));
        div.innerHTML = `${months[month - 1]} / ${year}`;

        document.querySelector(".js-monthNavigator").appendChild(div);
    };

    const handleMonthNavigator = (navigator) => {
        let year = Number(document.querySelector(".item.active").getAttribute("data-year"));
        let month = Number(document.querySelector(".item.active").getAttribute("data-month"));
        let nextMonth = navigator === "next" ? Number(month) + 1 : Number(month) - 1;

        if (nextMonth === 0) {
            nextMonth = 12;
            year = Number(year) - 1;
        }

        if (nextMonth === 13) {
            nextMonth = 1;
            year = Number(year) + 1;
        }

        generateMonthNavigator(nextMonth, year);
        fetchTransactions(nextMonth, year);
    };

    return (
        <>
            <MonthNavigator>
                <span
                    onClick={(e) => {
                        handleMonthNavigator("prev");
                    }}
                >
                    <IoIosArrowBack />
                </span>

                <div className="js-monthNavigator"></div>
                <span
                    onClick={(e) => {
                        handleMonthNavigator("next");
                    }}
                >
                    <IoIosArrowForward />
                </span>
            </MonthNavigator>
        </>
    );
};
