import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BodyLogin, LoginContainer } from "../Login/styled";

import Logo from "../../assets/logo.png";

import { register } from "../../services/Auth";
import useAuthStore from "../../stores/AuthStore";

export const RegisterPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword] = useState(false);
    // const handleShowClick = () => setShowPassword(!showPassword);

    // Verifica se já está logado
    const { isLoggedIn } = useAuthStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn()) {
            navigate("/");
        }
    }, []);

    // Função para criar uma nova conta
    async function action(event: React.FormEvent) {
        event.preventDefault();
        await register({ name: name, email: email, password: password })
            .then((response) => {
                navigate("/login?accountCreated=true");
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    }

    return isLoggedIn() ? (
        <></>
    ) : (
        <BodyLogin>
            <img src={Logo} alt="Logo Finances App" />

            <LoginContainer>
                <h1>Área Exclusiva</h1>
                <p>Insira os seus dados para criar uma nova conta.</p>
                <form onSubmit={action}>
                    <input
                        type="text"
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        placeholder="Nome e Sobrenome"
                    />
                    <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" />
                    <input type={showPassword ? "text" : "password"} placeholder="Senha" onChange={(e) => setPassword(e.target.value)} />
                    <button
                        type="submit"
                        onClick={() => {
                            setLoading(!loading);
                        }}
                    >
                        {loading ? "Criando conta..." : "Criar conta"}
                    </button>
                </form>
                <Link to="/login">Já tenho uma conta</Link>
            </LoginContainer>
        </BodyLogin>
    );
};
